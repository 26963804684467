import * as React from 'react';
import { Link } from 'gatsby';

export default function AboutPage() {
  return (
    <main>
      <h1>About This Site</h1>
      <Link to='/'>Back to home right now again now</Link>
    </main>
  )
}